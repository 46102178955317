<template>
  <v-app id="all">
    <v-app-bar v-if="$store.state.user" app color="var(--bg-1)" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title v-if="$store.state.user_type === 'partner'" @click="$router.push('/partner/dashboard')">Rapid-OOD</v-toolbar-title>
        <v-toolbar-title v-if="$store.state.user_type === 'admin'" @click="$router.push('/admin/dashboard')">Rapid-OOD</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="logout">
          <v-icon>logout</v-icon>
        </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-if="$store.state.user" v-model="drawer" color="var(--bg-1)" fixed left dark temporary>
        <div class="navigation-drawer-header" @click="$router.push('/partner/profile')">
          <h2>Hello<br>{{ $store.state.user_information.name }}</h2>
          <span class="email">{{ $store.state.user_information.email }}</span>
        </div>

        <v-list dark v-if="$store.state.user_type === 'partner'">
          <v-list-item v-for="(drawer_item, drawer_index) in partner_drawer_items" :key="drawer_index" @click="$router.push(drawer_item.link)">
            <v-list-item-icon>
              <v-icon :color="$router.currentRoute.path === drawer_item.link ? 'red' : 'unset'">{{ drawer_item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ drawer_item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list dark v-if="$store.state.user_type === 'admin'">
          <v-list-item v-for="(drawer_item, drawer_index) in admin_drawer_items" :key="drawer_index" @click="$router.push(drawer_item.link)">
            <v-list-item-icon>
              <v-icon :color="$router.currentRoute.path === drawer_item.link ? 'red' : 'unset'">{{ drawer_item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ drawer_item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>


        <div id="social-buttons">
          <v-btn v-for="(social_button, social_button_index) in social_buttons" :key="social_button_index" icon x-large target="_blank" :href="social_button.link">
            <v-icon>{{ social_button.icon }}</v-icon>
          </v-btn>
        </div>
    
    </v-navigation-drawer>

    <v-main>
      <router-view/>
      <v-footer color="var(--bg-2)" padless>
          <v-row justify="center" no-gutters>
            <v-btn v-for="(link, index) in links" :key="index" color="white" text rounded class="my-2" :href="link.link" :target="link.target">
              {{ link.text }}
            </v-btn>
            <v-col class="py-4 text-center white--text" cols="12" color="var(--bg-2)">
              {{ new Date().getFullYear() }} — <strong>Rapid-OOD GmbH</strong>
            </v-col>
          </v-row>
        </v-footer>

        <v-overlay :value="$store.state.loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import { auth } from './firebase'

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    partner_drawer_items: [
      {
        icon: 'home',
        title: 'Dashboard',
        link: '/partner/dashboard'
      },
      {
        icon: 'palette',
        title: 'Objects',
        link: '/partner/objects'
      },
      {
        icon: 'payments',
        title: 'Payments',
        link: '/partner/payments'
      },
      {
        icon: 'mdi-account-circle',
        title: 'Profile',
        link: '/partner/profile'
      }
    ],
    admin_drawer_items: [
      {
        icon: 'home',
        title: 'Dashboard',
        link: '/admin/dashboard'
      },
      {
        icon: 'palette',
        title: 'Objects',
        link: '/admin/objects'
      },
      {
        icon: 'edit',
        title: 'Modelling',
        link: '/admin/modelling'
      },
      {
        icon: 'storefront',
        title: 'Stores',
        link: '/admin/stores'
      },
      {
        icon: 'shopping_cart',
        title: 'Orders',
        link: '/admin/orders'
      },
      {
        icon: 'label',
        title: 'Labels',
        link: '/admin/labels'
      },  
      {
        icon: 'dns',
        title: 'Server',
        link: '/admin/servers'
      },
      {
        icon: 'query_stats',
        title: 'Analytics',
        link: '/admin/analytics'
      },
      {
        icon: 'account_balance',
        title: 'Financials',
        link: '/admin/financials'
      },
      {
        icon: 'mdi-account-circle',
        title: 'Profile',
        link: '/admin/profile'
      },
      {
        icon: 'settings',
        title: 'Config',
        link: '/admin/config'
      }
    ],
    social_buttons: [
      {
        link: 'https://www.instagram.com/rapid_ood/',
        icon: 'mdi-instagram'
      },
      {
        icon: 'mdi-twitter',
        link: 'https://twitter.com/rapid_ood'      
      },
      {
        icon: 'shopify',
        link: 'https://shop.rapid-ood.com'
      }
    ],
    links: [
        {
          text: 'Shop',
          link: 'https://shop.rapid-ood.com',
          target: '_blank'
        },
        {
          text: 'Imprint',
          link: 'https://shop.rapid-ood.com/pages/impressum',
          target: '_blank'
        },
        {
          text: 'Privacy',
          link: '/privacy',
          target: '_self'
        },
        {
          text: 'Terms of Service',
          link: '/terms',
          target: '_self'
        }
    ]
  }),

  methods: {
    logout: function() {
      auth.signOut();
      window.location.reload();
    }
  },

  mounted: function() {
    let vThis = this;

    auth.onAuthStateChanged((user) => {
      if (user) {
        vThis.$store.commit('login', user);
        //vThis.$router.push('/dashboard');
      } else {
        vThis.$router.push('/login');
      }
    });
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');

#all {
  --primary: #4473C4;
  --accent: #F7D967;
  --bg-1: #0f0f0f;
  --bg-2: #202125;
  --bg-3: #3B4043;
  --bg-4: #5B6063;
  --text-1: #fff;
  --text-2: #BFBFBF;
  --checkbox: var(--accent);

  font-family: 'Lexend Deca', sans-serif !important;
}


.v-navigation-drawer {
  color: #BFBFBF;
}

.v-toolbar__title:hover {
  cursor: pointer;
}

.navigation-drawer-header {
  height: 100px;
  width: 100%;
  background-color: #1976d277;
  position: relative;
  border-radius: 0 0 15px 15px;
  margin-bottom: 20px;
  padding: 5px;
}

.navigation-drawer-header:hover {
  cursor: pointer;
}

.v-main {
  padding-bottom: 100px !important;
}

.navigation-drawer-header h2 {
  font-size: 20px;
}

.navigation-drawer-header .email {
  font-size: 14px;
}

.v-navigation-drawer .v-list-item {
  background-color: var(--bg-2);
  margin: 5px;
  border-radius: 7px;
}

.v-navigation-drawer #social-buttons {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
}

.v-navigation-drawer #social-buttons .v-btn {
  margin: 0 5px;
}

.v-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.v-main__wrap {
  background-color: #f1f1f1;
}


#header-banner {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 50px;
    color: #f1f1f1;
    background: linear-gradient(90deg, #000, #393939);
    border-radius: 25px;
    padding: 15px;
}

.center-button {
  text-align: center;
}

.v-list {
  background-color: transparent !important;
}

.v-list-item {
  overflow: hidden !important;
}
</style>